import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

const styles = makeStyles((theme) => ({
  Avatar: {
    marginTop: "3px !important",
    marginLeft: "6px !important",
    backgroundColor: "#0B557B !important",
    width: "120px !important",
    height: "125px !important",
    borderRadius: "50% !important",
    border: "10px solid #D2E2EA !important",
    boxShadow: "0 0 0px 10px #EAF8FF !important",
    fontSize: "50px !important"
  },
  endCall: {
    marginLeft: "5px !important",
    marginBottom: "3px !important",
    marginTop: "2px !important",
    borderRadius: "22px !important",
    backgroundColor: "#F44336 !important"
  },
  ConferanceCall: {
    marginBottom: "3px !important",
    marginTop: "2px !important",
    borderRadius: "22px !important",
    backgroundColor: "#757ce8 !important"
  },
  ConferanceCallSpan: {
    marginRight: "2px  !important",
    marginTop: "1px !important",
  },
  initialsNum: {
    marginLeft: "7px !important",
    marginBottom: "2px !important",
    marginTop: "5px !important",
    fontSize: '20px !important',
    fontWeight: "8px !important"
  },
  BoxNum: {
    flexDirection: 'row !important',
    marginLeft: "2px !important",
  },
  tyIncoming: {
    fontSize: '26px !important',
    color: "#346077 !important",
    marginBottom: "-1rem !important",
  },
  tyDuration: {
    fontSize: '16px !important',
    color: "#515151 !important",
    fontWeight: "600px !important",
    lineHeight: "normal !important",
    marginTop:"15px !important"
  },
  tySpan: {
    fontSize: '15px !important',
    color: "#5B5B5B !important",
    fontWeight: "400px !important",
    lineHeight: "normal !important"
  },
  ButtonVoicemail: {
    marginLeft: "10px !important",
    marginTop: "4px !important",
    borderRadius: "22px !important",
    backgroundColor: "#F44336 !important",
    fontSize: '12px !important',
  },
  ButtonAnswer: {
    backgroundColor: "#66BB69 !important",
    marginTop: "4px !important",
    borderRadius: "22px !important",
  },
  ListItem: {
    display: 'block !important',
    textAlign: 'center !important'
  },
  commonListItemIcon: {
    width: "25px !important",
    height: "50px !important",
    borderRadius: "50% !important",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
  activeBackground: {
    backgroundColor: "#116089 !important",
  },
  unactiveBackground: {
    backgroundColor: "#EAF8FF !important",
  },
  cstmbox : {
    marginTop: "unset !important",
    padding: "4px 0px !important"
  },
  cstmboxs : {
    marginTop: "unset !important",
    padding: "5px 0px !important"
  }
  

  
}));

export default styles;