import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useContractors from "hooks/useContractors";
import useAccessControl from "hooks/useAccessControl";
import useApplication from "hooks/useApplication";
import { statusContractor } from "api/contractors";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./ContractorColumnData";
import useStyles from "components/TaskPreview/styles-jss";

export default function ContractorData() {
  const classes = useStyles();
  const navigate = useNavigate()
  const { canUpdateContractor, canDeleteContractor } = useAccessControl()

  const {
    search,
    contractorsReducer: { results, query, loading, meta },
    getContractors,
    changePage,
    changePerPage,
  } = useContractors();

  const changeStatusContractorHandler = async (contractor, contractorId) => {
    try {
      Swal.fire({
        title: contractor.status == "INACTIVE" ? "Active Contractor?" : "Inactive Contractor?",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        showCancelButton: true,
        customClass: {
          title: "swal-text",
          popup: "swal-text"
        },
        cancelButtonColor: "#d33",
        confirmButtonText: contractor.status == "INACTIVE" ? "Yes, Active" : "Yes, Inactive",
      }).then((result) => {
        if (result.isConfirmed) {
          contractor.status = contractor.status === "INACTIVE" ? "ACTIVE" : "INACTIVE";
          statusContractor(contractorId)
            .then(() => {
              search(query);
              Swal.fire({
                toast: true,
                timer: 3000,
                position: "top-right",
                title: contractor.status == "INACTIVE" ? "Inactive Contractor Successfully!" : "Active Contractor Successfully!",
                showConfirmButton: false,
                icon: "success"
              })
            })
            .catch((e) => { });
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getContractors({
      search: query,
      page: newPage + 1,
      per_page: meta?.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getContractors({
      search: query,
      page: meta?.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const transformedResults = results?.map((contractor) => ({
    id: contractor?.id,
    alpha_ref: contractor?.alpha_ref,
    name: contractor?.name,
    phone: contractor?.phone,
    email: contractor?.email,
    status: contractor?.status,
  }));

  const updateStatusContractor = async (data) => {
    const result = await changeStatusContractorHandler(data.row, data.id);
    return result;
  }

  const renderAction = (data) => (
    <div style={{ display: "flex", gap: "8px", width: "100%" }}>
      {canUpdateContractor() &&
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 2.0 }}
          size="small"
          onClick={() => navigate(`/app/contractors/${data.id}`)}
        >
          View
        </Button>
      }
      {canDeleteContractor() &&
        <Button
          variant="contained"
          sx={{
            mr: 2.0,
            backgroundColor: data.row.status === "INACTIVE" ? "gray" : "green"
          }}
          size="small"
          onClick={() => updateStatusContractor(data)}
        >
          {data.row.status}
        </Button>
      }
    </div>
  );

  return (
    <>
      <DataGrid
        columns={columns(renderAction)}
        rows={transformedResults}
        pageSize={meta?.per_page}
        rowsPerPageOptions={[meta?.per_page]}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangeRowsPerPage}
        paginationMode="server"
        rowCount={meta?.total}
        page={meta?.current_page - 1}
        disableSelectionOnClick
        autoHeight
        className={classes.dataGrid}
      />
    </>
  );
}
