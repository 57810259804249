import { useEffect, useState } from "react";
import { Box, CircularProgress, IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import useMembership from "hooks/useMembership";
import { DataGrid } from "@mui/x-data-grid";
import { sendMsgRequest } from "api/connect";
import Toast from "components/Toast";
import Swal from "sweetalert2";
import useSystem from "WebR/hooks/useSystem";
import { useNavigate } from "react-router-dom";
import "../../../src/components/Callincoming/index.css";

export default function Contract() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMessageCardVisible, setIsMessageCardVisible] = useState(false);
  const { call } = useSystem();
  const {
    search,
    getMemberships,
    membershipReducer: { results, loading, query, meta },
    changePage,
    changePerPage,
  } = useMembership();


  useEffect(() => {
    search(query);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getMemberships(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ContactLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/members/${params.id}`;
    const handleLinkClick = (event) => {
        event.preventDefault();
        navigate(url, { replace: true });
    };
    return (
        <a href="#" onClick={handleLinkClick} className='link'>
            {params.value}
        </a>
    );
};

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getMemberships({
      search: query,
      page: newPage + 1,
      per_page: meta?.per_page,
    });
  };


  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getMemberships({
      search: query,
      page: meta?.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const conectResults = results?.map((contractor) => ({
    id: contractor?.id,
    name: contractor?.name,
    cell_phone: contractor?.cell_phone,
  }));
  const handleCloseMsg = () => {
    setAnchorEl(null);
    setIsMessageCardVisible(false);
  };

  const handleShowMessageCard = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMessageCardVisible(true);
  };

  const handleSendMessage = async (message, CellPhone) => {
    let convertedNumber = CellPhone.replace(/[\s\(\)-]/g, "");

    try {
      sendMsgRequest({
        to: convertedNumber,
        text: message,
      })
        .then((res) => {
          setIsMessageCardVisible(false);
          Toast.fire({
            position: "bottom-end",
            icon: "success",
            title: "Message Sent Successfully!",
          });
        })
        .finally(() => {
        });
    } catch (error) {
      console.error('Error sending message:', error.message);
      handleCloseMsg()
    }

  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 350,
      renderCell: (params) => <ContactLink params={params} />,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 450,
      editable: true,
      renderCell: (params) => <ContactLink params={params} />,
    },
    {
      field: 'cell_phone',
      headerName: 'Phone',
      width: 350,
      editable: true,
    },
    // {
    //   field: 'message',
    //   headerName: 'Message',
    //   type: 'string',
    //   width: 350,
    //   editable: true,
    //   renderCell: (params) => (
    //     <IconButton>
    //       <Mail onClick={() => handleShowMessageCard(params?.row?.id)} />
    //       <Menu
    //         id={`menu-${params?.row?.id}`}
    //         anchorEl={anchorEl}
    //         open={opend}
    //         onClose={handleCloseMsg}
    //       >
    //         <MessageCard
    //           onSend={handleSendMessage}
    //           CellPhone={params?.row?.cell_phone}
    //           loading={loading}
    //         />
    //       </Menu>
    //     </IconButton>
    //   ),
    // },
    {
      field: 'Call',
      headerName: 'Call',
      sortable: false,
      width: 350,
      renderCell: (params) => (
        <IconButton>
          <PhoneIcon
            onClick={() => callContact(params.row.id, params.row.cell_phone, params.row.name, "ConferanceCall")}
          />
        </IconButton>
      ),
    },
  ];

  const callContact = (member, number, nameValue) => {
    if (localStorage.getItem("status") === "offline") {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-right",
        text: "You Are Not Online!",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
    } else if (localStorage.getItem("status") === "online") {
      // const numericPart = number.replace(/^\+1 /, "");
      const numericPart = number.replace(/[^\d]/g, '');
      console.log(numericPart, 'numericPart');
      call('+' + numericPart, nameValue);
    }
  };


  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={conectResults}
            pageSize={meta?.per_page}
            rowsPerPageOptions={[meta?.per_page]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      )}
    </>
  );
}
Contract.defaultProps = {
  fileable_type: "Membe",
  fileable_id: "1asfdsfsfdsfdsf"
};

