import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

// Styles
import dayjs from "dayjs";
import { Button, Chip } from "@mui/material";
import { statuses } from "utils/jobStatuses";
import columns from "./tableData";
import useStyles from "./styles";

const MembershipJobs = ({ jobs, openView }) => {
  const classes = useStyles();
  const [jobData, setJobData] = useState([]);

  // console.log(jobData[0].job.description, 'jobData');
  
  useEffect(() => {
    const newJobs = [];
    if (jobs.length > 0) {
      for (let job in jobs) {
        newJobs.push({
          id: jobs[job].id,
          name: jobs[job].category?.name,
          contractor: jobs[job].contractor
            ? jobs[job].contractor.name
            : "Pending",
          date: dayjs(jobs[job].created_at).format("MM/DD/YYYY"),
          dateInv: "-",
          amount: "-",
          description:jobs[job].code.description,
          invoice: "Pending",
          dateRecorded: dayjs(jobs[job].created_at).format("MM/DD/YYYY"),
          status: jobs[job].status,
          job: jobs[job],
        });
      }
      setJobData(newJobs);
    }
  }, [jobs]);

  const renderStatus = (data) => {
    return (
      // <Typography
      //   style={{ backgroundColor: statuses[data.row.status] }}
      //   className={classes.status}
      // >
      //   {data.row.status}
      // </Typography>
      <Chip
      sx={{
        background: statuses[data.row.status],
        color: "white",
      }}
      label={data.row.status}
    />
    );
  };

  // const renderDescription = jobData[0].job.description;
    
  const renderAction = (data) => (
    <Button size="small" onClick={() => openView(data.row.job)}>
      View
    </Button>
  );

  return (
    <React.Fragment>
      <DataGrid
        rows={jobData}
        columns={columns(renderStatus, renderAction)}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight
        autoPageSize
        className={classes.dataGrid}
        density="compact"
      />
    </React.Fragment>
  );
};

export default MembershipJobs;
