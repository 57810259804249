import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import SearchInput from "components/SearchInput";
import useUser from "hooks/useUser";
import useAccessControl from "hooks/useAccessControl";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "components/TaskPreview/styles-jss";
import columns from "../Components/UserColumnData";
import { locationRequest } from "api/users";
import useServiceLocations from "hooks/useServiceLocations";

export default function Users() {
  const classes = useStyles();
  const [all, setAll] = useState([]);
  const [officeId, setOfficeId] = useState("All");
  const [dropdownValue, setdropdownValue] = useState(false);
  const { canDeleteUser, canUpdateUser, canCreateUser } = useAccessControl();
  const navigate = useNavigate();
  const { deleteUser } = useUser();
  const { setPage } = useApplication();
  const {
    usersReducer: { results, query, loading, meta },
    search,
    getUsers,
    changePage,
    changePerPage,
  } = useUser();
  const {
    serviceLocationsReducer: { resultsData },
    getServiceLocations,
  } = useServiceLocations();

  useEffect(() => {
    setPage({
      title: "Users",
      path: "/app/users",
    });
    getServiceLocations();
    search(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setOfficeId(e.target.value);
    locationRequest(e.target.value)?.then((response) => {
      setAll(response?.data?.response);
      setdropdownValue(true);
    });
  };

  const handleSearch = (searchValue) => {
    search(searchValue);
  };

  const deleteUserData = async (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      customClass: {
        title: "swal-text",
        popup: "swal-text",
      },
      confirmButtonText: "Yes, Delete User",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(params)
          .then((res) => {
            search(query);
            Swal.fire({
              toast: true,
              timer: 3000,
              position: "top-right",
              title: "User Deleted Successfully!",
              showConfirmButton: false,
              icon: "success",
            });
          })
          .catch((err) => {
            if (err.response) {
              toast.error("User Deleted unSuccessful", {
                style: {
                  fontFamily: ["Open Sans", "sans-serif"],
                },
              });
            }
          });
      }
    });
  };

  const userResults = results?.map((userData) => ({
    id: userData?.id,
    name: userData?.name,
    email: userData?.email,
    role: userData?.role,
    extension: userData?.extension,
    uuid: userData?.uuid,
    bgColor: userData?.bgColor,
  }));

  const settingLocationResults = resultsData.map((settingList) => ({
    id: settingList?.id,
    office: settingList?.office,
  }));

  const renderAction = (data) => (
    <div style={{ display: "flex", gap: "4px" }}>
      {canUpdateUser() && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 2 }}
          onClick={() => navigate(`/app/users/${data.row.uuid}`)}
        >
          Edit
        </Button>
      )}
      {canDeleteUser() && (
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => deleteUserData(data.id)}
        >
          Delete
        </Button>
      )}
    </div>
  );

  const locationResults = Array.isArray(all.result)
    ? all.result.map((userData) => ({
        id: userData?.id,
        name: userData?.name,
        email: userData?.email,
        role: userData?.role,
        extension: userData?.extension,
        uuid: userData?.uuid,
        bgColor: userData?.bgColor,
      }))
    : [];

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getUsers({
      search: query,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getUsers({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  return (
    <PaperBlock
      title="Users"
      loading={loading}
      actions={
        <>
          <Box sx={{ minWidth: 120, padding: "8.5px 14px !important" }}>
            <FormControl fullWidth>
              <label>Service location</label>
              <Select
                sx={{ mr: 2, whiteSpace: "nowrap", minWidth: "auto" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={officeId}
                // label="Service location"
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value="All">ALL</MenuItem>
                {settingLocationResults.map((officeList) => (
                  <MenuItem key={officeList.id} value={officeList.id}>
                    {officeList.office}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          {canCreateUser() && (
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: "auto" }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/app/users/create")}
            >
              Add user
            </Button>
          )}
        </>
      }
    >
      <Box style={{ overflow: "auto" }}>
        <DataGrid
          columns={columns(renderAction)}
          rows={dropdownValue === false ? userResults : locationResults}
          pageSize={Number(meta?.per_page)}
          rowsPerPageOptions={[Number(meta?.per_page)]}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          paginationMode="server"
          rowCount={meta?.total}
          page={meta?.current_page - 1} // Set the current page
          disableSelectionOnClick
          autoHeight
          className={classes.dataGrid}
        />
      </Box>
    </PaperBlock>
  );
}
