import axios from "utils/axios";

const GET_INVOICES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/invoices`;
const GET_OPEN_INVOICES = `${process.env.REACT_APP_API_DOMAIN}/api/v1/invoices/open-invoices`;
const GET_OPEN_INVOICE_COLUMN = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice/open-invoices`;

export const getInvoicesRequest = (params, pagination) => {
  return axios.post(`${GET_INVOICES_ROUTE}/index`, {}, {
    params: { ...params, ...(pagination || {}) },
  });
};

export const viewInvoiceRequest = (id) =>
  axios.get(`${GET_INVOICES_ROUTE}/${id}`);

export const emailInvoiceRequest = (id) =>
  axios.get(`${GET_INVOICES_ROUTE}/send-email/${id}`);

export const downloadInvoiceRequest = (id) =>
  axios.get(`${GET_INVOICES_ROUTE}/get-pdf/${id}`, {
    responseType: 'blob',
  });

export const deletedCheckDataRequest = (data) =>
  axios.post(`${GET_INVOICES_ROUTE}/deleted-check`, data);

export const createInvoiceRequest = (params) =>
  axios.post(`${GET_INVOICES_ROUTE}`, params);

export const getOpenInvoiceDataRequest = (data) => axios.post(GET_OPEN_INVOICES, data);
export const getOpenInvoiceContractorRequest = (data) => axios.post(GET_OPEN_INVOICE_COLUMN, data);
