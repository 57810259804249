import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import LoadingButton from "@mui/lab/LoadingButton";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";
import useUtils from "hooks/useUtils";
import useAccessControl from "hooks/useAccessControl";
import { useEffect } from "react";
// import ServiceLocationPicker from "components/ServiceLocationPicker";

const VendorValidationSchema = Yup.object().shape({
  alpha: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  phone: Yup.string().required("required"),
  email: Yup.string().email("Please enter valid email").required("Required"),
  vendor_type_id: Yup.string().required("Required"),
  service_location_id: Yup.number().nullable(),
});

const VendorForm = ({ form, onSubmit, withHeadingLine }) => {
  // const { canManageServiceLocationUser } = useAccessControl();
  const navigate = useNavigate();
  const {
    utils: { vendor_types },
  } = useUtils();

  const formik = useFormik({
    initialValues: {
      service_location_id: null,
      service_location: null,
      vendor_type_id: '',
      ...form
    },
    validationSchema: VendorValidationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },

  });

  useEffect(() => {
    if (form && form.service_location) {
      formik.setValues({
        ...formik.values,
        service_location_id: form.service_location.id,
        service_location: {
          id: form.service_location.id,
          label: form.service_location.name
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={4}>
        {withHeadingLine && (
          <HeadingLine title="Vendor information" />
        )}
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={3}>
            <FormField
              label="Alpha"
              value={formik.values.alpha}
              onChange={formik.handleChange}
              name="alpha"
              required
              error={formik.touched.alpha && Boolean(formik.errors.alpha)}
              helperText={formik.touched.alpha && formik.errors.alpha}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              name="name"
              required
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputMask
              value={formik.values.phone}
              onChange={formik.handleChange}
              mask="(+1) 999-999-9999"
            >
              {() => <FormField
                required
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                label="Phone"
                name="phone"
              />}
            </InputMask>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              type="email"
              required
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Vendor type"
              value={formik.values.vendor_type_id}
              onChange={formik.handleChange}
              name="vendor_type_id"
              select
              error={formik.touched.vendor_type_id && Boolean(formik.errors.vendor_type_id)}
              helperText={formik.touched.vendor_type_id && formik.errors.vendor_type_id}
            >
              {vendor_types.map((val) => (
                <MenuItem key={val.id} value={val.id}>
                  {val.label}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="text"
          sx={{ mr: 1 }}
          onClick={() => navigate("/app/vendors")}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={false}
          loadingPosition="center"
          variant="contained"
          onClick={formik.submitForm}
          disabled={formik.isInvalid}
        >
          {form.alpha ? "Update vendor" : "Create vendor"}
        </LoadingButton>
      </Box>
    </form>
  );
};

VendorForm.defaultProps = {
  withHeadingLine: true,
  form: {
    alpha: "",
    name: "",
    phone: "",
    email: "",
    type: null,
  },
};

export default VendorForm;
