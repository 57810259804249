import { DataGrid } from '@mui/x-data-grid';
import useMembership from 'hooks/useMembership';
import React, { useEffect } from 'react'
import columns from './NewColumn';
import { Button, CircularProgress } from '@mui/material';
import useAccessControl from 'hooks/useAccessControl';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteMemberRequest } from 'api/membership';

const NewMember = () => {
  const { canUpdateMember } = useAccessControl();
  const navigate = useNavigate();
  const {
    search,
    membershipReducer: { openMember, loading, meta, query },
    getOpenMember,
    changePage,
    changePerPage,
  } = useMembership();

  useEffect(() => {
    getOpenMember(query);
  }, [])

  const handleSearch = (value) => {
    search(value);
  };

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getOpenMember({
      search: query,
      page: newPage + 1,
      perPage: meta?.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getOpenMember({
      search: query,
      page: meta?.current_page,
      perPage: parseInt(event.target.value),
    });
  };

  const deleteMemeberHandler = (membership_id) => {
    try {
      Swal.fire({
        title: "Cancel member",
        text: "Are you sure you wish to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        customClass: {
          title: "swal-text",
          popup: "swal-text"
        },
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMemberRequest(membership_id)
            .then(() => {
              search();
              Swal.fire({
                toast: true,
                timer: 3000,
                position: "top-right",
                title: 'Member Canceled Successfully!',
                showConfirmButton: false,
                icon: "success"
              })
            })
            .catch((e) => { });
        }
        navigate('/app/members')
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const renderAction = (data) => (
    <div style={{ display: 'flex', mt: 1.5 }}>
      {canUpdateMember() && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 2.0 }}
          onClick={() => navigate(`/app/members/${data?.row?.membership_id}`)}
        >
          View
        </Button>
      )
      }
      <Button
        variant="contained"
        color="error"
        size="small"
        onClick={() => deleteMemeberHandler(data?.id)}
      >
        Cancel
      </Button>
    </div>
  );

  const newMemberResults = openMember?.map((newMemberData) => ({
    id: newMemberData?.id,
    name: newMemberData?.name,
    address: newMemberData?.address,
    cell_phone: newMemberData?.cell_phone,
    email: newMemberData?.email,
    status: newMemberData?.status,
    membership_id: newMemberData?.membership_id
  }));
  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <>
          <div style={{ overflow: "auto" }}>
            <DataGrid
              rows={newMemberResults}
              columns={columns(renderAction)}
              autoHeight
              pageSize={meta?.per_page} // Use meta.per_page as the page size
              rowsPerPageOptions={[meta?.per_page]} // Set rows per page options
              onPageChange={handleChangePage} // Handle page change
              onPageSizeChange={handleChangeRowsPerPage} // Handle rows per page change
              paginationMode="server" // Enable server-side pagination
              rowCount={meta?.total} // Set the total number of rows
              page={meta?.current_page - 1} // Set the current page
              disableSelectionOnClick
            />
          </div>
        </>)}
    </>
  )
}

export default NewMember
