import { useEffect, useState } from "react";
import { useRcState } from "../system/Provider";

const useSystem = () => {
  const { callService, setCall } = useRcState();
  const [callServiceType, setCallType] = useState();


  useEffect(() => {
    if (callService?.callData?.session) {
      callService?.callData?.session?.on("accepted", (e) => {
        setCall({ state: "in-call" });
        setCallType(() => callService?.callData?.type)
        localStorage.setItem("terminatedValue", callService?.callData?.type)
      });
      callService?.callData?.session.on("rejected", () => {
        console.log("rejected")
        reset();
      });

      callService?.callData?.session?.on("progress", function () {
        console.log("Event: Progress");
      });

      callService?.callData?.session?.on("terminated", function () {
        if (localStorage.getItem("terminatedValue") !== "conference") {
          reset()
        }
        else if (callService?.callData?.type === "conference") {
          reset()
        }
        // if(callService.callData.type === "conferenceOutbound"){
        //       // reset()
        //       setCallData({
        //         call_id: '',
        //         type: "Inbound",
        //         state: "in-call",
        //         phoneNo: callService.psession.phoneNo,
        //         contact: callService.psession.contact,
        //         memberId: null,
        //         isUnknown: false,
        //         session:callService.psession.session
        //       });

        // }
        // else if(localStorage.getItem("terminatedValue") === "confernce"){
        //   reset()
        // }
      })

      callService?.callData?.session?.on("failed", function () {
        console.log("Event: Failed", arguments);
        // Toast.fire({
        //   title: "Please Check Your Microphone",
        //   icon: "error",
        // });
      });
      callService?.callData?.session?.on("muted", function () {
        console.log("Event: Muted");
      });
      callService?.callData?.session?.on("cancel", function () {
        console.log("Event: cancel");
      });
      callService?.callData?.session?.on("dtmf", function () {
        console.log("Event: dtmf");
      });

      callService?.callData?.session?.on("refer", function () {
        console.log("Event: refer");
      });
      callService?.callData?.session?.on("replaced", function () {
        console.log("Event: replaced");
      });
      callService?.callData?.session?.on("connecting", function () {
        console.log("Event: connecting");
      });
      callService?.callData?.session?.on("unmuted", function () {
        console.log("Event: Unmuted");
      });
      callService?.callData?.session?.on("bye", function () {
        console.log("Event: Bye");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callService?.callData?.session?.id]);

  const reset = async () => {
    await setCall({
      call_id: null,
      mode: "widget",
      state: null,
      type: null,
      phoneNo: "",
      contact: "",
      isUnknown: false,
      memberId: null,
      session: null,
      muted: false,
      onHold: false,
      recording: false,
      volume: 0.3,
      toVoicemail: false,
      toForward: false,
    });
  };

  const setCallData = async (data) => await setCall(data);

  const hasCall = () => callService?.callData?.type !== null;

  const answer = () => {
    if (hasCall()) {
      callService?.callData?.session?.accept()
        .then(function () {
          setCallData({ state: "in-call" });
        })
        .catch(function (e) {
          console.error("Accept failed", e?.stack || e);
        });
    }
  };

  const reject = async () => {
    if (hasCall()) {
      await callService?.callData?.session.terminate();
    }
  };

  const endCall = async () => {
    if (hasCall()) {
      await callService?.callData?.session?.terminate();
    }
  };


  const call = (number, countryId, nameValue) => {

    // const { extension } = callService;
    // console.log("extension",extension)

    // countryId =
    //   countryId ||
    //   (extension &&
    //     extension.regionalSettings &&
    //     extension.regionalSettings.homeCountry &&
    //     extension.regionalSettings.homeCountry.id) ||
    //   null;
    // const session = webPhone.userAgent.invite(number, {
    //   fromNumber: username,
    //   homeCountryId,
    // });

    //9548623901*4063
    //7706963187

    // let phoneNumber = number;
    // let convertedNumber = phoneNumber.replace(/-/g, "");

    // const session = callService?.webPhone?.userAgent?.invite('16152828580', {
    //   fromNumber: null,
    //   homeCountryId: '1'
    // });

    // setCallData({
    //   type: "Outbound",
    //   state: "in-call",
    //   phoneNo: "16152828580", //friendlyName
    //   contact: nameValue,
    //   isUnknown: false,
    //   session,
    //   ConferanceCall: ""
    // });
    let phoneNumber = number;
    let convertedNumber = phoneNumber.replace(/-/g, "");

    const session = callService?.webPhone?.userAgent?.invite(convertedNumber, {
      fromNumber: null,
      homeCountryId: '1'
    });

    setCallData({
      type: "Outbound",
      state: "in-call",
      phoneNo: convertedNumber, //friendlyName
      contact: nameValue,
      isUnknown: false,
      session,
      ConferanceCall: ""
    });

    //MTODO: v1/calls/start { type: "outbound", phone_number: session.request.from.uri.user  }
    //setCallData vine in interiorul requestului. O sa mai fie nevoie de
    // axios
    //   .post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/calls/start`, {
    //     type: "outbound",
    //     phone_number: session.request.from.uri.user,
    //   })
    //   .then(() => {
    //     setCallData({
    //       type: "Outbound",
    //       state: "ringing",
    //       phoneNo: number, //friendlyName
    //       contact: session.request.to.displayName,
    //       isUnknown: false,
    //       session,
    //     });
    //   });
  };


  const callout = (number, countryId, nameValue) => {
    console.log("callnumber", number);

    // const { extension } = callService;
    // console.log("extension",extension)

    // countryId =
    //   countryId ||
    //   (extension &&
    //     extension.regionalSettings &&
    //     extension.regionalSettings.homeCountry &&
    //     extension.regionalSettings.homeCountry.id) ||
    //   null;
    // const session = webPhone.userAgent.invite(number, {
    //   fromNumber: username,
    //   homeCountryId,
    // });

    //9548623901*4063
    //7706963187

    const session = callService?.webPhone?.userAgent?.invite(number, {
      fromNumber: null,
      homeCountryId: '1'
    });

    setCallData({
      type: "conferenceOutbound",
      state: "in-call",
      phoneNo: number, //friendlyName
      contact: nameValue,
      isUnknown: false,
      session,
      ConferanceCall: "ConferanceCall"
    })

    //MTODO: v1/calls/start { type: "outbound", phone_number: session.request.from.uri.user  }
    //setCallData vine in interiorul requestului. O sa mai fie nevoie de
    // axios
    //   .post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/calls/start`, {
    //     type: "outbound",
    //     phone_number: session.request.from.uri.user,
    //   })
    //   .then(() => {
    //     setCallData({
    //       type: "Outbound",
    //       state: "ringing",
    //       phoneNo: number, //friendlyName
    //       contact: session.request.to.displayName,
    //       isUnknown: false,
    //       session,
    //     });
    //   });
  };

  const hold = () => {
    if (hasCall()) {
      callService?.callData?.session?.hold()
        .then(() => {
          console.log("Hold");
          setCall({ onHold: true });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const unhold = () => {
    if (hasCall()) {
      callService?.callData?.session?.unhold()
        .then(() => {
          console.log("Un hold");
          setCall({ onHold: false });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const mute = () => {
    if (hasCall()) {
      callService?.callData?.session?.mute();
      setCall({ muted: true });
    }
  };

  const unmute = () => {
    if (hasCall()) {
      callService?.callData?.session?.unmute();
      setCall({ muted: false });
    }
  };

  const transfer = () => { };

  const transferToVM = () => { };

  const setVolume = (value) => {
    callService.callData.session.ua.audioHelper.setVolume(value);
  };

  const startRecording = () => {
    if (hasCall()) {
      callService?.callData?.session?.startRecord()
        .then(function () {
          // axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/calls/start-recording`, {
          //   call_id: callService.callData.call_id,
          // });
          // MTODO: v1/calls/start-recording  { call_id: callService.callData.call_id }
          console.log("Recording Started");
          setCall({ recording: true });
        })
        .catch(function (e) {
          console.error("Recording Start failed", e.stack || e);
        });
    }
  };

  const stopRecording = () => {
    if (hasCall()) {
      callService.callData.session
        .stopRecord()
        .then(function () {
          // axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/calls/start-recording`, {
          //   call_id: callService.callData.call_id,
          // });
          // MTODO: v1/calls/start-recording  { call_id: callService.callData.call_id }
          setCall({ recording: false });
        })
        .catch(function (e) {
          console.error("Recording Start failed", e.stack || e);
        });
    }
  };


  const ToVoiceMail = () => {
    if (hasCall()) {
      callService.callData.session
        .toVoicemail()
        .then(() => {
          setCall({ toVoicemail: true });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const Forward = (num) => {
    // if (hasCall()) {
    console.log("num", num)
    const strnum = num.toString()
    callService.callData.session
      .forward(strnum)
      .then(() => {
        console.log("toForward");
        setCall({ toForward: true });
      })
      .catch((e) => {
        console.error(e);
      });
    // }
  };




  return {
    data: callService.callData,
    setCallData,
    answer,
    reject,
    endCall,
    call,
    callout,
    mute,
    unmute,
    hold,
    unhold,
    startRecording,
    setVolume,
    transfer,
    transferToVM,
    ToVoiceMail,
    stopRecording,
    Forward
  };
};

export default useSystem;