import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button } from "@mui/material";
import useAccessControl from "hooks/useAccessControl";

const ContractorRow = ({ data, deleteContractor }) => {
  const navigate = useNavigate();
  const {
    canUpdateContractor,
    canDeleteContractor } = useAccessControl()
  return (
    <TableRow
    // key={contractor.uuid}
    >
      <TableCell component="th" scope="row" width="25%">
        {data.alpha_ref}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data.name}
      </TableCell>
      <TableCell component="th" scope="row" width="20%">
        {data.phone}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data.email}
      </TableCell>
      <TableCell component="th" scope="row" align="right" width="20%" >
        <div style={{ display: "flex", gap: "8px", width: "100%" }}>
          {canUpdateContractor() &&
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 2.0 }}
              size="small"
              onClick={() => navigate(`/app/contractors/${data.contractor_id}`)}
            >
              View
            </Button>
          }
          {canDeleteContractor() &&
            <Button
              variant="contained"
              style={{ background: data.status == "INACTIVE" ? "gray" : "green" }}
              size="small"
              sx={{ mr: 2.0 }}
              onClick={() => deleteContractor(data.contractor_id)}
            >
              {data.status}
            </Button>
          }
          {/* {data.status === "ACTIVE" && (
            <Button
              sx={{ whiteSpace: "nowrap", minWidth: 'auto', width: "15%" }}
              onClick={() => navigate("")}
              variant="contained"
            >
              <Tooltip title="Import contractor">
                <AddIcon style={{ fontSize: "20px" }} />
              </Tooltip>
            </Button>
          )} */}

        </div>
      </TableCell>
    </TableRow>
  );
};

export default ContractorRow;
