import { Box, Button, CircularProgress } from '@mui/material'
import useInvoices from 'hooks/useInvoices';
import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import columns from 'pages/App/Members/View/components/MembershipJobs/tableData';
import useStyles from 'components/TaskPreview/styles-jss';
import { useNavigate } from 'react-router-dom';


const InvoiceData = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    invoicesReducer: { results, meta, loading, query },
    searchInvoices,
    changePage,
    changePerPage,
  } = useInvoices();

  useEffect(() => {
    searchInvoices(query);
  }, [query])

  const handleChangePage = (newPage) => {
    changePage(newPage);
    searchInvoices({
      search: query,
      page: newPage + 1,
      per_page: meta ? meta.per_page : 10,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    searchInvoices({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const renderStatus = (data) => { };

  const renderAction = (data) => (
    <Button size="small" color="primary" style={{ backgroundColor: "#116089", color: "#fff" }} onClick={() => navigate(`/app/invoices/${data?.id}`)} >
      View
    </Button>
  );

  const transformedResults = results?.map((invoice) => ({
    id: invoice?.id,
    description: invoice?.description,
    amount: invoice?.amount,
    created_at: invoice?.created_at,
    qbe_sent: invoice?.qbe_sent,
    status: invoice?.status,
  }));

  return (
    <>
      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            loading={loading}
            rows={transformedResults}
            columns={columns(renderStatus, renderAction)}
            pageSize={meta?.per_page}
            rowsPerPageOptions={[meta?.per_page]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
            className={classes.dataGrid}
          />
        </Box>
      )}
    </>
  )
}
export default InvoiceData;