import { Button, CircularProgress, Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import useStyles from 'components/TaskPreview/styles-jss';
import useInvoices from 'hooks/useInvoices';
import React, { useEffect } from 'react'
import columns from '../openInvoiceDataColumn';
import { useNavigate } from 'react-router-dom';

const OpenInvoiceData = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const {
        invoicesReducer: { OpenInvoice, query, loading, meta },
        getOpenInvoice,
        changePage,
        changePerPage,
    } = useInvoices();

    useEffect(() => {
        getOpenInvoice(query);
    }, [])

    const handleChangePage = (newPage) => {
        changePage(newPage);
        getOpenInvoice({
            search: query,
            page: newPage + 1,
            per_page: meta ? meta.per_page : 10,
        });
    };

    const handleChangeRowsPerPage = (event) => {
        changePerPage(event);
        getOpenInvoice({
            search: query,
            page: meta.current_page,
            per_page: parseInt(event.target.value),
        });
    };

    const transformedResults = (OpenInvoice?.result || [])?.map((contractor) => ({
        id: contractor?.id,
        description: contractor?.description,
        amount: contractor?.amount,
        created_at: contractor?.created_at,
        qbe_sent: contractor?.qbe_sent,
        status: contractor?.status

    }));

    const renderAction = (data) => (
        <Button size="small" color="primary" style={{ backgroundColor: "#116089", color: "#fff" }} onClick={() => navigate(`/app/invoices/${data?.id}`)}>
            View
        </Button>
    );

    return (
        <>
            {loading ? (
                <CircularProgress style={{ margin: "20px auto", display: "block" }} />
            ) : (
                <Box sx={{ overflow: "auto" }}>
                    <DataGrid
                        columns={columns(renderAction)}
                        rows={transformedResults}
                        pageSize={meta?.per_page} // Use meta.per_page as the page size
                        rowsPerPageOptions={[meta?.per_page]} // Set rows per page options
                        onPageChange={handleChangePage} // Handle page change
                        onPageSizeChange={handleChangeRowsPerPage} // Handle rows per page change
                        paginationMode="server" // Enable server-side pagination
                        rowCount={OpenInvoice?.meta?.total} // Set the total number of rows
                        page={meta?.current_page - 1}
                        autoHeight
                        disableSelectionOnClick
                        className={classes.dataGrid}
                    />
                </Box>
            )}
        </>
    )
}

export default OpenInvoiceData
