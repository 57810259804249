import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "../../../../../src/components/Callincoming/index.css";


const OpenInvoiceLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/invoices/${params.id}`;

    const handleLinkClick = (event) => {
        event.preventDefault();
        navigate(url, { replace: true });
    };

    return (
        <a href="#" onClick={handleLinkClick} className='link'>
            {params.value}
        </a>
    );
};

const columns = (renderAction) => [
    {
        field: 'id',
        headerName: 'ID',
        type: "string",
        sortable: false,
        renderCell: (params) => <OpenInvoiceLink params={params} />,
    },
    {
        field: 'description',
        headerName: 'Description',
        type: "string",
        sortable: false,
        flex: .1,
    },
    {
        field: 'amount',
        headerName: 'Amount',
        type: "string",
        sortable: false,
        width: 150,
    },
    {
        field: 'created_at',
        headerName: 'Date',
        type: "string",
        sortable: false,
        flex: .1,
        renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
    },
    {
        field: 'qbe_sent',
        headerName: 'QBT Sent',
        type: "string",
        sortable: false,
        flex: .1,
    },
    {
        field: 'status',
        headerName: 'Status',
        type: "string",
        sortable: false,
        flex: .1,
    },
    {
        field: 'action',
        headerName: 'Action',
        type: "string",
        sortable: false,
        renderCell: renderAction,
    },
];

export default columns;