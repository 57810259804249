import { CircularProgress, Box } from "@mui/material";
import { useEffect } from "react";
import useMembershipshipCallLog from "hooks/useMembershipCallLog";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./components/CallLogColumn";

export default function Calllog() {
  const {
    getMemberships,
    membershipCallLogReducer: { results, query, meta, loading },
    changePage,
    changePerPage,
  } = useMembershipshipCallLog();


  useEffect(() => {
    getMemberships()
  }, []);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getMemberships({
      search: query,
      page: newPage + 1,
      perPage: meta?.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getMemberships({
      search: query,
      page: meta?.current_page,
      perPage: parseInt(event.target.value),
    });
  };

  const callLogResults = results?.map((contractor) => ({
    id: contractor?.id,
    name: contractor?.from?.name ?? "Unknown",
    phoneNumber: contractor?.from?.phoneNumber,
    result: contractor?.result,
    duration: contractor?.duration,
    startTime: contractor?.startTime,
  }));


  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={callLogResults}
            pageSize={meta?.per_page}
            rowsPerPageOptions={[meta?.per_page]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      )}
    </>
  );
}

Calllog.defaultProps = {
  fileable_type: "Membe",
  fileable_id: "1asfdsfsfdsfdsf"
};
