import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "../../../../../../../src/components/Callincoming/index.css";

const InvoiceLink = ({ params }) => {
  const navigate = useNavigate();
  const url = `/app/invoices/${params.id}`;
  const handleLinkClick = (event) => {
    event.preventDefault();
    navigate(url, { replace: true });
  };
  return (
    <a href="#" onClick={handleLinkClick} className='link'>
      {params.value}
    </a>
  );
};

const columns = (renderStatus, renderAction) => [
  {
    field: "id",
    headerName: "ID",
    sortable: true,
    type: "string",
    flex: 1,
    renderCell: (params) => <InvoiceLink params={params} />,
  },
  {
    field: "description",
    headerName: "Description",
    sortable: true,
    type: "string",
    flex: 2,
  },
  {
    field: "amount",
    headerName: "Amount",
    sortable: true,
    type: "string",
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "Date",
    type: "string",
    flex: 1.2,
    // renderCell: renderStatus,
    renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
  },
  {
    field: "qbe_sent",
    headerName: "QBE Sent",
    type: "date",
    editable: false,
    sortable: true,
    flex: 1.5,
  },
  {
    field: "status",
    headerName: "Status",
    type: "date",
    editable: false,
    sortable: true,
    flex: 1,
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    flex: 0.7,
    sortable: false,
    renderCell: renderAction,
  },
];

export default columns;
