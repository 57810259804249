import React, { useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import JobUpdateForm from "components/JobSheetModal/components/JobUpdateForm";
import JobEstimates from "components/JobSheetModal/components/JobEstimates";
import Dispatched from "components/JobRequestModal/components/Dispatched";
import useAccessControl from "hooks/useAccessControl";

const ViewJob = ({ job, changeJob, getData, removeContractor }) => {
  const [estimatesPanel, setEstimatesPanel] = useState("estimate");
  const [contractorPanel, setContractorPanel] = useState("contractor");
  const { canReadJobEstimate } = useAccessControl();

  const handleEstimatePanelChange = (_event, isExpanded) => {
    setEstimatesPanel(isExpanded ? "estimate" : false);
  };
  const handleContractorPanelChange = (_event, isExpanded) => {
    setContractorPanel(isExpanded ? "contractor" : false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Accordion expanded={true}>
          <AccordionSummary>
            <Typography fontWeight={"bold"}>Job Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider />
            <JobUpdateForm
              membership={job.membership}
              state={job}
              setState={changeJob}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item md={6}>
        {canReadJobEstimate() && (
          <Accordion
            expanded={estimatesPanel === "estimate"}
            onChange={handleEstimatePanelChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={"bold"}>Estimates</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <JobEstimates
                open
                job_id={job?.id}
                job={job}
                estimate={job?.estimate}
                onAccepted={getData}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {(job.contractor || job.status === "DISPATCH") && (
          <Accordion
            expanded={contractorPanel === "contractor"}
            onChange={handleContractorPanelChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={"bold"}>
                Contractor {!job.dispatched && <>(Dispatch Request Pending)</>}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <Dispatched
                job={job}
                removeContractor={removeContractor}
                onSubmit={(data) => changeJob(data)}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};

export default ViewJob;
