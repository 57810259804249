import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Divider,
  Typography,
  MenuItem,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Chip,
  CircularProgress,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ContentBlock from "components/ContentBlock";
import useApplication from "hooks/useApplication";
import FormField from "components/FormField";
import useUtils from "hooks/useUtils";
import useUser from "hooks/useUser";
import { tasksColors } from "utils/statusColors";
import ServiceLocationPicker from "components/ServiceLocationPicker";
import useAccessControl from "hooks/useAccessControl";
import useStyles from "./styles";

import '../../../../utils/sweetalert.css'

export default function Create() {
  const { canManageServiceLocationUser } = useAccessControl();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const { utils } = useUtils();
  const { createUser } = useUser();
  const { setPage } = useApplication();
  useEffect(() => {
    setPage({
      title: "Users",
      path: "/app/users",
    });
    // eslint-disable-next-line
  }, []);

  const AccountDetailsValidationSchema = Yup.object().shape({
    role: Yup.string().required("This field is required"),
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().nullable(),
    service_location_id: Yup.number().nullable(),
    email: Yup.string().email("Please enter valid email").required("This field is required"),
    password: Yup.string().required("This field is required")
      .min(6, "Password too Short!")
      .max(255, "Password too Long!")
      .nullable(),
    password_confirmation: Yup.string().required("This field is required")
      .when("password", {
        is: (password) => password?.length > 0,
        then: Yup.string().required("This field is required"),
        otherwise: Yup.string().nullable(),
      })
      .oneOf([Yup.ref("password"), null], "Confirm password does not match with new password"),
    bgColor: Yup.string(),
    txtColor: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      role: "EMPLOYEE",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      service_location_id: 1,
      service_location: null,
      password_confirmation: "",
      bgColor: "lightgray",
      txtColor: "black",
    },
    validationSchema: AccountDetailsValidationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await createUser(values);
        if (response.data) {
          setLoading(false);
          Swal.fire({
            toast:true,
            position:"top-right",
            icon: "success",
            title: "User Created Successfully!",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          navigate(`/app/users/${response.data.uuid}`);
        }
      } catch (error) {
        setLoading(false);
        if (error?.response.data.email) {
          Swal.fire({
            toast:true,
            position:"top-right",
            icon: "error",
            showConfirmButton: false,
            timer: 4000,
            title: error?.response.data.email[0]
          });
        } else if (error?.response.data.role) {
          Swal.fire({
            toast:true,
            position:"top-right",
            icon: "error",
            showConfirmButton: false,
            timer: 4000,
            title: error?.response.data.role
          });
        }
        if (error?.response?.data?.errors) {
          Object.keys(error.response.data.errors).forEach((field) => {
            if (error.response.data.errors[field][0] !== undefined)
              formik.setFieldError(field, error.response.data.errors[field][0]);
          });
        }
      }
    },
  });

  return (
    <ContentBlock title="Users" icon="ion-ios-clipboard-outline">
      <Box sx={{ width: { xs: "100%", md: 500 } }}>
        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
          <Box>
            <Typography
              variant="h6"
              fontWeight="bold"
              color="primary"
              sx={{ mb: 2 }}
            >
              Account details
            </Typography>
            <FormField
              name="role"
              label="Role"
              select
              value={formik.values.role}
              onChange={formik.handleChange}
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={formik.touched.role && formik.errors.role}
            >
              {utils.roles.map((role) => (
                <MenuItem key={`role-${role.id}`} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </FormField>
            <FormField
              type="text"
              name="first_name"
              label="First Name"
              value={formik.values?.first_name.trim()}
              onChange={(e) =>
                formik.setFieldValue("first_name", e.target.value)
              }
              error={formik.touched.first_name && Boolean(formik.errors.first_name)}
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
            <FormField
              type="text"
              name="last_name"
              label="Last Name"
              value={formik.values?.last_name.trim()}
              onChange={(e) =>
                formik.setFieldValue("last_name", e.target.value)
              }
            />
            {canManageServiceLocationUser() && (
              
              <ServiceLocationPicker
                label="Service Location"
                InputLabelProps={{ shrink: true }}
                value={formik.values.role === "CUSTOMER_SERVICE" ? '01-Seattle': formik.values?.service_location}
                name="service_location_id"
                onChange={(val) => {
                  formik.setFieldValue('service_location', val);
                  formik.setFieldValue('service_location_id', val?.id);
                }}
              />
            )}
            <FormField
              type="text"
              name="email"
              label="Email"
              autoComplete="new-email"
              value={formik.values?.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormField
              type="password"
              label="New Password"
              autoComplete="new-password"
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue("password", e.target.value)}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <FormField
              type="password"
              label="Confirm new password"
              onChange={(e) =>
                formik.setFieldValue("password_confirmation", e.target.value)
              }
              error={
                formik.touched.password_confirmation &&
                Boolean(formik.errors.password_confirmation)
              }
              helperText={
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation
              }
            />
          </Box>
          <Box mt={2}>
            <Typography
              variant="h6"
              fontWeight="bold"
              color="primary"
              sx={{ mb: 2 }}
            >
              Tasks colors
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="secondary"
              sx={{ mb: 2 }}
            >
              Background color
            </Typography>
            <Box>
              <FormControl sx={{ mb: 0 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  sx={{ display: "flex", flexDirection: "row", mb: 0 }}
                >
                  {tasksColors.map((defaultColor) => (
                    <FormControlLabel
                      key={defaultColor}
                      value={defaultColor}
                      label=""
                      checked={false}
                      onChange={(e) =>
                        formik.setFieldValue("bgColor", e.target.value)
                      }
                      control={
                        <Radio
                          icon={<CircleIcon sx={{ color: defaultColor }} />}
                          checkedIcon={
                            <RadioButtonCheckedIcon
                              sx={{ color: defaultColor }}
                            />
                          }
                        />
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Typography mb={2} variant="body2">
                or choose a custome one
              </Typography>
              <FormField
                type="color"
                value={formik.values.bgColor}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  formik.setFieldValue("bgColor", e.target.value)
                }
                error={formik.touched.bgColor && Boolean(formik.errors.bgColor)}
                helperText={formik.touched.bgColor && formik.errors.bgColor}
              />
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="secondary"
              sx={{ mb: 2 }}
            >
              Text color
            </Typography>
            <Box>
              <FormControl sx={{ mb: 0 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  sx={{ display: "flex", flexDirection: "row", mb: 0 }}
                >
                  {tasksColors.map((defaultColor) => (
                    <FormControlLabel
                      key={defaultColor}
                      value={defaultColor}
                      onChange={(e) =>
                        formik.setFieldValue("txtColor", e.target.value)
                      }
                      label=""
                      checked={false}
                      control={
                        <Radio
                          icon={<CircleIcon sx={{ color: defaultColor }} />}
                          checkedIcon={
                            <RadioButtonCheckedIcon
                              sx={{ color: defaultColor }}
                            />
                          }
                        />
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Typography mb={2} variant="body2">
                or choose a custome one
              </Typography>
              <FormField
                type="color"
                value={formik.values.txtColor}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  formik.setFieldValue("txtColor", e.target.value)
                }
                error={
                  formik.touched.txtColor && Boolean(formik.errors.txtColor)
                }
                helperText={formik.touched.txtColor && formik.errors.txtColor}
              />
            </Box>
            <Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="secondary"
                sx={{ mb: 2 }}
              >
                Preview
              </Typography>
              <Chip
                label={`${formik.values?.first_name} ${formik.values?.last_name}`}
                sx={{
                  color: formik.values.txtColor,
                  bgcolor: formik.values.bgColor,
                  height: 50,
                  minWidth: 180,
                  borderRadius: 100,
                  fontSize: 20,
                }}
              />
            </Box>
          </Box>
          <Button
            onClick={formik.handleSubmit}
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
            // disabled={!formik.isValid 
            //   // || (formik.values.first_name === ''
            //   // && formik.values.last_name === ''
            //   // && formik.values.email === ''
            //   // && formik.values.password === ''
            //   // && formik.values.password_confirmation === '')
            // }
          >
            Create account
            {loading && (
              <Box pl={2}>
                <CircularProgress color="secondary" size={16} />{" "}
              </Box>
            )}
          </Button>
        </form>
      </Box>
    </ContentBlock>
  );
}
