import React, { useContext } from "react";
import {
  Grid,
  Button,
  Box,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from "@mui/material";
import HeadingLine from "components/HeadingLine";
import ApiSelect from "components/ApiSelect";
import { membershipTypesRequest } from "api/utilities";
import AddNew from "components/AddNew";
import useAuth from "hooks/useAuth";
import ServiceLocationPicker from "components/ServiceLocationPicker";
import useAccessControl from "hooks/useAccessControl";
import Member from "./FormParts/Member";
import MemberApplicationContext from "./MemberApplicationFormContext";
import Property from "./FormParts/Property";
import PaymentInformation from "./FormParts/PaymentInformation";
import Signature from "./FormParts/Signature";
import MailingAddress from "./FormParts/MailingAddress";
import { ShippingFields } from "pages/App/Batch/Process/components";
import OtherMember from "./FormParts/OtherMember";

const special = ["1st", "2nd", "3rd", "4th", "5th"];

const MembershipForm = () => {
  const { canManageServiceLocationUser } = useAccessControl();
  const { loggedIn } = useAuth();
  const {
    form,
    setForm,
    addMember,
    addProperty,
    defaultLocation,
    handleSubmit,
    formLoading,
    validationErrors,
    validateField
  } = useContext(MemberApplicationContext);

  const handleChangeShipping = (data) => {
    const _shipping = { ...form.shipping, ...data };
    setForm({ shipping: _shipping });
  };

  return (
    <form autocomplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {loggedIn && (
          <Grid item md={12} sm={12} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="out_of_network"
                  checked={form.membership.out_of_network}
                  onChange={(e) => {
                    const membership = { ...form.membership };
                    membership.out_of_network = e.target.checked;
                    setForm({ membership });
                  }}
                />
              }
              label="Out of network"
            />
          </Grid>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <HeadingLine title="Property Type" />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ApiSelect
            inputProps={{
              label: "Property Type",
              value: form.membership.membership_type,
              onChange: (e) => {
                const membership = { ...form.membership };
                membership.membership_type = e.target.value;
                membership.new_property_cost = ''
                membership.membership_cost = ''
                setForm({ membership });
              },
            }}
            source={membershipTypesRequest}
            reducer="membership_type"
          />
        </Grid>
      </Grid>
      {form.membership.membership_type && (
        <>
          <Grid item md={12} sm={12} xs={12} mt={2}>
            <HeadingLine title="Communications Preferences" />
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel id="radio-buttons-membership-card">Membership card</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-membership-card"
                    name="membership_card"
                    value={form.membership.membership_card}
                    onChange={(e) => {
                      const membership = { ...form.membership };
                      membership.membership_card = e.target.value;
                      setForm({ membership });
                    }}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="hard" />
                    <FormControlLabel value="2" control={<Radio />} label="digital" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel id="radio-buttons-newsletter">Newsletter</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-newsletter"
                    name="newsletter"
                    value={form.membership.newsletter}
                    onChange={(e) => {
                      const membership = { ...form.membership };
                      membership.newsletter = e.target.value;
                      setForm({ membership });
                    }}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="hard" />
                    <FormControlLabel value="2" control={<Radio />} label="digital" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel id="radio-buttons-communications">Communications</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-communications"
                    name="communications"
                    value={form.membership.communications}
                    onChange={(e) => {
                      const membership = { ...form.membership };
                      membership.communications = e.target.value;
                      setForm({ membership });
                    }}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="hard" />
                    <FormControlLabel value="2" control={<Radio />} label="digital" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel id="radio-buttons-invoice-preference">Invoice preference</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-invoice-preference"
                    name="invoice_preference"
                    value={form.membership.invoice_preference}
                    onChange={(e) => {
                      const membership = { ...form.membership };
                      membership.invoice_preference = e.target.value;
                      setForm({ membership });
                    }}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="hard" />
                    <FormControlLabel value="2" control={<Radio />} label="digital" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {canManageServiceLocationUser() && (
            <Grid item md={12} sm={12} xs={12} mt={2}>
              <HeadingLine title="Service Location" />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={4}>
                  <ServiceLocationPicker
                    label="Service Area"
                    InputLabelProps={{ shrink: true }}
                    value={form.service_location}
                    name="service_location_id"
                    onChange={(val) => {
                      const membership = { ...form.membership };
                      membership.service_location = val;
                      membership.service_location_id = val?.id;
                      setForm({ membership });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container pt={2} spacing={2}>
            {form.members.map((member, index) => (
              <Member
                key={`member-${index}`}
                membership={form.membership}
                // member={form.members[0]}
                member={member}
                index={index}
                form={form}
                setForm={setForm}
                defaultLocation={defaultLocation}
                loggedIn={loggedIn}
                validationErrors={validationErrors}
                validateField={validateField}
              />
            ))}
            {form.members.length < 3 && (
              <Grid item md={12} sm={12} xs={12}>
                <AddNew
                  onClick={addMember}
                  label={`Add ${special[form.members.length]} Member`}
                  nomargin
                />
              </Grid>
            )}
          </Grid>
          <Box mt={2}>
            <ShippingFields
              shippingData={form.shipping}
              withActions={true}
              onChange={handleChangeShipping}
            />
          </Box>
          <Grid container pt={2} spacing={2}>
            <OtherMember
              form={form}
              setForm={setForm}
              defaultLocation={defaultLocation}
              loggedIn={loggedIn}
            />
          </Grid>
          <Grid container pt={2} spacing={2}>
            {form.properties.map((property, index) => (
              <Property
                key={`property-${index}`}
                index={index}
                property={property}
                form={form}
                setForm={setForm}
                defaultLocation={defaultLocation}
                validationErrors={validationErrors}
                validateField={validateField}
              />
            ))}
            {form.properties.length < 5 && (
              <Grid item md={12} sm={12} xs={12}>
                <AddNew
                  onClick={addProperty}
                  label={`Add ${special[form.properties.length]
                    } Property Details`}
                  nomargin
                />
              </Grid>
            )}
          </Grid>
          {/* {loggedIn && ( */}
          <Grid container spacing={2}>
            <MailingAddress
              form={form}
              setForm={setForm}
              defaultLocation={defaultLocation}
              loggedIn={loggedIn}
            />
          </Grid>
          {/* )} */}
          <Grid container pt={2} spacing={2}>
            <PaymentInformation
              membership={form.membership}
              form={form}
              setForm={setForm}
              loggedIn={loggedIn}
            />
          </Grid>
          <Grid container pt={2} spacing={2}>
            <Signature
              membership={form.membership}
              form={form}
              setForm={setForm}
              loggedIn={loggedIn}
              validationErrors={validationErrors}
              validateField={validateField}
            />
          </Grid>
          <Box p={2} mt={3} display="flex" justifyContent="center">
            <Button
              disabled={formLoading}
              role="submit"
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit Application
              {formLoading && (
                <Box pl={2}>
                  <CircularProgress size={16} />{" "}
                </Box>
              )}
            </Button>
          </Box>
        </>
      )}
    </form>
  );
};

export default MembershipForm;
