import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ".././../utils/sweetalert.css"
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { useTheme } from "@mui/material";
import Swal from "sweetalert2";

// import { viewMembershipRequest } from 'api/membership';
// import ApiSelect from "components/ApiSelect";
// import { getUsersRequest } from "api/users";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import FormField from "components/FormField";
import {
  createJobRequest,
  getJob,
  removeContractorRequest
} from "api/jobs";
import { statuses, statusList } from "utils/jobStatuses";
import useJobs from "hooks/useJobs";
import QModal from "components/QModal";
import Dispatched from "components/JobRequestModal/components/Dispatched";
import JobUpdateForm from "./components/JobUpdateForm";
import JobEstimates from "./components/JobEstimates";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    borderBottom: "solid 1px #ddd",
  },
  modalTitle: {
    padding: 0,
  },
  modalSubtitle: {
    color: "gray",
  },
  dialogContent: {
    overflow: "auto",
  },
  actionsLabel: {
    color: "gray",
  },
  whited: {
    "& div": {
      color: "white",
    },
  },
  COMPLETED: { background: statuses.COMPLETED },
  DISPATCH: { background: statuses.DISPATCH },
  ESTIMATE: { background: statuses.ESTIMATE },
  SIGNED: { background: statuses.SIGNED },
  CANCEL: { background: statuses.CANCEL },
  RESERVICE: { background: statuses.RESERVICE },
  OPEN: { background: statuses.OPEN },
  NEW: { background: statuses.OPEN },
}));

const JobSheetModal = ({
  handleClose,
  membership_id,
  viewJob,
  onRefresh,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();

  const [estimatesPanel, setEstimatesPanel] = useState("estimate");
  const [dispatchPanel, setDispatchPanel] = useState("dispatch");

  const handleEstimatePanelChange = (_event, isExpanded) => {
    setEstimatesPanel(isExpanded ? "estimate" : false);
  };
  const handleDispatchPanelChange = (_event, isExpanded) => {
    setDispatchPanel(isExpanded ? "dispatch" : false);
  };

  const { changeField, changeJobStatus } = useJobs();
  const [membership, setMembership] = useState({});
  const [state, _setState] = useState({
    id: null,
    membership_id: membership_id,
    status: "NEW",
    contact_id: null,
    property_id: null,
    category_id: null,
    code_id: null,
    description: "",
    contact: {},
    property: {},
    assigned: 0,
    loaded: false,
  });


  const setState = (values) => _setState({ ...state, ...values });

  useEffect(() => {
    getJob(viewJob.id).then((res) => {
      // if (viewJob.status === "NEW") {
      // changeField(viewJob.id, "status", "OPEN");
      viewJob.status = res.data.status;
      // }
      setState({ ...viewJob, loaded: true });
      setMembership(res.data.membership);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership_id, viewJob]);

  const onSendEstimates = () => {
    if (onRefresh) {
      onRefresh();
    }
    navigate(`/app/jobs/${state.id}`);
  };

  const removeContractor = () => {
    removeContractorRequest(state.id).then((res) => {
      setState(res.data);
    });
  };

  const handleDuplicateJob = (status) => {
    createJobRequest({
      ...state,
      status
    }).then((res) => {
      if (res.data.id) {
        navigate(`/app/jobs/${res.data.id}`)
        Swal.fire({
          title: "Success",
          text: "Job Duplicated Successfully!",
          customClass: {
            title: "swal-text",
            popup: "swal-text"

          },
          confirmButtonColor: theme.palette.primary.main,
        });
      }
    });
  }

  return (
    <QModal
      dialogProps={{
        open: true,
        title: `#${state.id} Job Request`,
        subtitle: dayjs(state.created_at).format("MM/DD/YYYY"),
      }}
      handleClose={handleClose}
    >
      <>
        {!state.loaded && (
          <Box
            display="flex"
            height={120}
            justifyContent="center"
            alignItems="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography>Please wait...</Typography>
            </Box>
          </Box>
        )}
        {state.loaded && (
          <Grid container spacing={2}>
            <Grid item sm={8} md={9}>
              {state.draft && (
                <Box mb={2}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={100}
                    className={classes.actionsLabel}
                  >
                    Web Form Details
                  </Typography>
                  <Divider width={200} />
                  <Grid sx={{ pt: 1 }} container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography
                        pt={1}
                        variant="body2"
                      >{`${state.draft.first_name} ${state.draft.last_name} (Primary Contact)`}</Typography>
                      {state.draft.contact_first && (
                        <Typography
                          pt={1}
                          variant="body2"
                        >{`${state.draft.contact_first} ${state.draft.contact_last} (Contact Name)`}</Typography>
                      )}
                      <Typography variant="body2">{`${state.draft.email}`}</Typography>
                      <Typography variant="body2">{`${state.draft.phone_no}`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography paddingTop={1} variant="body2">
                        {state.draft.service_address}
                      </Typography>
                      <Typography paddingTop={1} variant="body2">
                        {state.draft.zip}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
              <JobUpdateForm
                membership={membership}
                state={state}
                setState={setState}
                onSubmit={() => {
                  onRefresh && onRefresh();
                  handleClose();
                }}
              />
              {state.status === "Aman" && (
                <Accordion
                  expanded={estimatesPanel === "estimate"}
                  onChange={handleEstimatePanelChange}
                // style={{width:"240px"}}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight={"bold"}>Estimates</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider />
                    <JobEstimates
                      open
                      job_id={state.id}
                      job={state}
                      onRefresh={onSendEstimates}
                      hideOpenEstimate
                    />
                  </AccordionDetails>
                </Accordion>
              )}
              {state.status === "DISPATCH" && (
                <Accordion
                  expanded={dispatchPanel === "dispatch"}
                  onChange={handleDispatchPanelChange}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight={"bold"}>
                      {state.dispatched ? "Dispatched to" : "Dispatch Request"}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider />
                    <Dispatched
                      open
                      job={state}
                      removeContractor={removeContractor}
                      onSubmit={onSendEstimates}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
            <Grid item sm={4} md={3}>
              <Box mt={2}>
                {console.log(state, 'state')}
                
                <Typography
                  variant="subtitle2"
                  className={classes.actionsLabel}
                >
                  Status
                </Typography>
                <FormField
                  select
                  value={state.status}
                  className={classes.whited}
                  classes={{ root: classes[state.status] }}
                  onChange={(e) => {
                    if (e.target.value === 'DUPLICATE') {
                      handleDuplicateJob(e.target.value);
                    } else {
                      if (e.target.value === 'RESERVICE') {
                        removeContractor();
                      }
                      changeJobStatus(state.id, e.target.value);
                      setState({ status: e.target.value })
                    }
                  }}
                >
                  {state.status === "NEW" && (
                    <MenuItem value={"NEW"}>NEW</MenuItem>
                  )}
                  {statusList.map((status) => (
                    <MenuItem key={`status-${status}`} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </FormField>
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    </QModal>
  );
};

export default JobSheetModal;
