import { CircularProgress, Box } from "@mui/material";
import { useEffect, useState } from "react";
import useVoiceCallMail from "hooks/useVoiseMail";
import { DataGrid } from "@mui/x-data-grid";

export default function Voicemail() {
  const [audioSrc, setAudioSrc] = useState(null);
  const {
    getVoiceCallMail,
    voiceCallMailReducer: { results, query, meta, loading, accessToken },
    changePage,
    changePerPage,
  } = useVoiceCallMail();

  useEffect(() => {
    getVoiceCallMail()
  }, []);
  useEffect(() => {
    // fetchAudio(data?.attachments[0]?.uri, accessToken);
  }, []);

  async function fetchAudio(uri, token) {
    const response = await fetch(uri, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    setAudioSrc(url);
  }
  const handleChangePage = (newPage) => {
    changePage(newPage);
    getVoiceCallMail({
      search: query,
      page: newPage + 1,
      perPage: meta?.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getVoiceCallMail({
      search: query,
      page: meta?.current_page,
      perPage: parseInt(event.target.value),
    });
  };

  const voiceCallResults = results?.map((voiceData) => ({
    id: voiceData?.id,
    name: voiceData.from?.name ?? "Unknown",
    phoneNumber: `${voiceData?.from?.phoneNumber || voiceData?.from?.extensionNumber}`,
    messageStatus: voiceData?.messageStatus,
    creationTime: voiceData?.creationTime,
    direction: voiceData?.direction,
    uri: voiceData?.attachments?.uri,
  }));

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    {
      field: 'phoneNumber',
      headerName: 'Phone',
      width: 300,
      editable: true,
    },
    {
      field: 'messageStatus',
      headerName: 'Status',
      width: 300,
      editable: true,
    },
    {
      field: 'uri',
      headerName: 'Duration',
      width: 400,
      editable: true,
      renderCell: (params) => {
        const audioSrc = params?.row?.audioSrc;
        return audioSrc ? (
          <audio controls src={audioSrc} />
        ) : (
          <audio controls src={audioSrc} />
        );
      },
    },
    {
      field: 'creationTime',
      headerName: 'Date	',
      sortable: false,
      width: 300,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
  ];

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={voiceCallResults}
            pageSize={meta?.per_page}
            rowsPerPageOptions={[meta?.per_page]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      )}
    </>
  );
}

Voicemail.defaultProps = {
  fileable_type: "Membe",
  fileable_id: "1asfdsfsfdsfdsf"
};