import { Box, Button, CircularProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import columns from '../../components/openContractorInvoice';
import { useNavigate } from 'react-router-dom';
import useInvoices from 'hooks/useInvoices';

const OpenContractorInvoiceColumnData = () => {
    const navigate = useNavigate();
    const {
        invoicesReducer: { openInvoiceContractor, query, loading, meta },
        getOpenInvoiceContractors,
        changePage,
        changePerPage,
    } = useInvoices();

    useEffect(() => {
        getOpenInvoiceContractors(query)
    }, [])

    const handleChangePage = (newPage) => {
        changePage(newPage);
        openInvoiceContractor({
            search: query,
            page: newPage + 1,
            per_page: meta ? meta.per_page : 10,
        });
    };

    const handleChangeRowsPerPage = (event) => {
        changePerPage(event);
        openInvoiceContractor({
            search: query,
            page: meta.current_page,
            per_page: parseInt(event.target.value),
        });
    };

    const contractorResults = openInvoiceContractor?.map((contractorData) => ({
        id: contractorData?.id,
        con: contractorData?.con,
        mem_name: contractorData?.mem_name,
        mem_street: contractorData?.mem_street,
        created_at: contractorData?.created_at,
        status: contractorData?.status,
        contractors_id: contractorData?.contractors_id
    }));

    const renderAction = (data) => (
        <Button size="small" color="primary" style={{ backgroundColor: "#116089", color: "#fff" }} onClick={() => navigate(`/app/viewInvContractorHoc/${data?.id}/${data?.row?.contractors_id}`)} >
            View
        </Button>
    );

    return (
        <>
            {loading ? (
                <CircularProgress style={{ margin: "20px auto", display: "block" }} />
            ) : (
                <Box sx={{ overflow: "auto" }}>
                    <DataGrid
                        columns={columns(renderAction)}
                        rows={contractorResults}
                        pageSize={meta?.per_page}
                        rowsPerPageOptions={[meta?.per_page]}
                        onPageChange={handleChangePage}
                        onPageSizeChange={handleChangeRowsPerPage}
                        paginationMode="server"
                        rowCount={openInvoiceContractor?.meta?.total}
                        page={meta?.current_page - 1}
                        autoHeight
                        disableSelectionOnClick
                    />
                </Box>
            )}
        </>
    )
}

export default OpenContractorInvoiceColumnData
