import { Box, Button, Chip, CircularProgress, TableCell } from '@mui/material';
import "../../../../../../src/components/Callincoming/index.css"
import { DataGrid } from '@mui/x-data-grid';
import AssignedToJob from 'components/AssignedToJob';
import Toast from 'components/Toast';
import useJobs from 'hooks/useJobs';
import useQueue from 'hooks/useQueue';
import React, { useEffect } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { statuses } from 'utils/jobStatuses';

const OpenJobsData = () => {
  const navigate = useNavigate()
  const { deleteJob } = useJobs();
  const { changeTaskUser } = useQueue();
  const {
    jobsReducer: { openJobs, query, meta, loading },
    getOpenJobs,
    changePage,
    changePerPage,
  } = useJobs();

  useEffect(() => {
    getOpenJobs(query);
  }, [])

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getOpenJobs(query, newPage + 1, meta?.per_page);
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getOpenJobs(query, meta?.current_page, parseInt(event.target.value));
  };
  const deleteJobData = async (params) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        title: "swal-text",
        popup: "swal-text"
      },
      confirmButtonText: 'Yes, Delete Job'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteJob(params)
          .then(res => {
            getOpenJobs(query);
            Swal.fire({
              toast: true,
              timer: 3000,
              position: "top-right",
              title: 'Job Deleted Successfully!',
              showConfirmButton: false,
              icon: "success",
            });
          }).catch(err => {
            if (err.response) {
              toast.error('Job Deleted Unsuccessful!', {
                style: {
                  fontFamily: ['Open Sans', 'sans-serif']
                }
              });

            }
          })
      }
    })
  }

  const openJobesResults = openJobs?.map((jobData) => ({
    id: jobData.id,
    name: jobData?.contractor?.name ?? "Unassigned",
    first_name: `${jobData?.contact?.first_name || ''} ${jobData?.contact?.last_name || ''}`,
    property: jobData?.property?.address,
    status: jobData?.status,
    priority: jobData?.priority,
    assigned_to: jobData?.assigned_to,
    description: jobData?.code?.description,
    contractor_id: jobData?.contractor_id,
    membership: jobData?.membership_id
  }));

  const renderAction = (data) => (
    <div style={{ display: "flex", gap: "4px", width: "100%" }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        sx={{ mr: 2.0 }}
        onClick={() => navigate(`/app/jobs/${data?.row?.id}`)}
      >
        View
      </Button>
      <Button
        variant="contained"
        color="error"
        size="small"
        sx={{ mr: 0.5 }}
        onClick={() => deleteJobData(data?.row?.id)}
      >
        Delete
      </Button>
    </div>
  );

  const OpenJobsLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/jobs/${params.id}`;
    const handleLinkClick = (event) => {
      event.preventDefault();
      navigate(url, { replace: true });
    };
    return (
      <a href="#" onClick={handleLinkClick} className='link'>
        {params.value}
      </a>
    );
  };

  const OpenContractorLink = ({ data }) => {
    const navigate = useNavigate();
    const url = `/app/contractors/${data.row.contractor_id}`;
    const handleLinkClick = (event) => {
      event.preventDefault();
      navigate(url, { replace: true });
    };
    return (
      <a href="#" onClick={handleLinkClick} className='link'>
        {data.value}
      </a>
    );
  };

  const OpenMembershipLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/members/${params.row.membership}`;
    const handleLinkClick = (event) => {
      event.preventDefault();
      navigate(url, { replace: true });
    };
    return (
      <a href="#" onClick={handleLinkClick} className='link'>
        {params.value}
      </a>
    );
  };

  const columns = (renderAction) => [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      width: 150,
      renderCell: (params) => <OpenJobsLink params={params} />,
    },
    {
      field: 'name',
      headerName: 'Contractor Name',
      width: 250,
      editable: true,
      renderCell: (data) => <OpenContractorLink data={data} />,
    },
    {
      field: 'first_name',
      headerName: 'Member Name',
      width: 250,
      editable: true,
      renderCell: (params) => <OpenMembershipLink params={params} />,
    },
    {
      field: 'property',
      headerName: 'Address',
      type: 'string',
      width: 200,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 200,
      editable: true,
      renderCell: (params) => (
        <TableCell component="th" scope="row">
          <Chip
            label={params.value}
            style={{ background: statuses[params.value], color: "white" }}
          />
        </TableCell>
      )
    },
    {
      field: 'assigned_to',
      headerName: 'CSA',
      type: "string",
      sortable: false,
      width: 300,
      renderCell: (params) => (
        <AssignedToJob
          value={params?.row?.assigned_to ?? 0}
          onChange={(e) => {
            params.assigned_to = e.target.value;
            changeTaskUser(params?.row?.id, e.target.value, module = "job", params?.row?.description);
            Toast.fire({
              title: 'Assigned To Updated Successfully!',
              icon: "success",
              position: "top-right",
              timer: 3000
            });
          }}
        />
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 200,
      renderCell: renderAction,
    },
  ];

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns(renderAction)}
            rows={openJobesResults}
            autoHeight
            pageSize={meta?.per_page} // Use meta.per_page as the page size
            rowsPerPageOptions={[meta?.per_page]} // Set rows per page options
            onPageChange={handleChangePage} // Handle page change
            onPageSizeChange={handleChangeRowsPerPage}
            rowCount={meta?.total} // Set the total number of rows
            page={meta?.current_page - 1} // Set the current page
            disableSelectionOnClick
          />
        </Box>
      )}
    </>
  )
}
export default OpenJobsData;