import React from "react";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import HeadingLine from "components/HeadingLine";
import FormField from "components/FormField";
import { makeStyles } from "@mui/styles";
import "../../../css/responsive.css"

const useStyle = makeStyles(() => ({
  signature: {
    "& input": {
      fontFamily: "Open Sans, sans-serif;",
    },
    "& input::placeholder": {
      fontFamily: "Open Sans, sans-serif;",
    },
  },
}));
const Signature = ({ setForm, form, loggedIn, validationErrors, validateField, index }) => {
  const handleChange = async (e) => {
    const _membership = { ...form.membership };
    _membership[e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setForm({ membership: _membership });
    if (typeof validateField === 'function') { // Check if validateField is a function
      await validateField(`membership.${e.target.name}`, e.target.value);
    } else {
    }
  };

  const handleImageChange = (event) => {
    const _membership = { ...form.membership };
    _membership.file = event.target.files[0]
    _membership.fileURL = URL.createObjectURL(event.target.files[0])
    setForm({ membership: _membership });
  }

  const { membership } = form;

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <HeadingLine title="Communications &amp; Disclosures" />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="paperless_communication"
              onChange={handleChange}
              checked={membership.paperless_communication}
            />
          }
          label={"Opt into paperless communications"}
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="pay_by_cc"
              onChange={handleChange}
              checked={membership.pay_by_cc}
            />
          }
          label={"I would like to pay by credit card, please call me"}
        />
        <br />
        {!loggedIn && (
          <FormControlLabel
            control={
              <Checkbox
                required
                color="primary"
                name="terms_acceptance"
                onChange={handleChange}
                checked={membership.terms_acceptance}
              />
            }
            label={
              "I have read and accept the Club's Terms, Conditions, Disclosures & Processing Fees."
            }
            error={validationErrors && validationErrors[`membership[${membership.id}].terms_acceptance`]} // Adjusted to use membership ID
            helperText={validationErrors && validationErrors[`membership[${membership.id}].terms_acceptance`]?.replace(`membership[${membership.id}].terms_acceptance`, "Terms Acceptance")}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <input
          type="file"
          name="file"
          accept="image/png, image/jpg, image/jpeg"
          className="form-control-file"
          onChange={handleImageChange}
        />
      </Grid>
      <Grid item md={4} sm={12} xs={12} sx={{ ml: 0 }}>
        <FormField
          label="Date"
          type="date"
          name="date"
          onChange={handleChange}
          value={membership.date}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </>
  );
};

export default Signature;
