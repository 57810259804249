import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Tooltip,
  Tab
} from "@mui/material";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import useApplication from "hooks/useApplication";
import useContractors from "hooks/useContractors";
import SearchInput from "components/SearchInput";
import PaperBlock from "components/PaperBlock";
import ContractorRow from "./components/ContractorRow";
import { statusContractor } from "api/contractors";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import ContractorBlocks from "components/ContractorBlocks";
import ".././../../../utils/sweetalert.css";
import useAccessControl from "hooks/useAccessControl";
import ContractorData from "./components/ContractorData";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OpenContractorInvoiceColumnData from "./components/OpenContractorInvoiceColumnData";

export default function Contractors() {
  const { setPage } = useApplication();
  const { canCreateContractor } = useAccessControl()
  const [tabValue, setTabValue] = useState("0");
  const {
    search,
    contractorsReducer: { results, query, loading, meta },
    getContractors,
    changePage,
    changePerPage,
  } = useContractors();
  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Contractors",
      path: "/app/contractors",
    });
    search(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (value) => {
    search(value);
  };


  const changeStatusContractorHandler = async (contractor, contractorId) => {
    try {
      Swal.fire({
        title: contractor.status == "INACTIVE" ? "Active Contractor?" : "Inactive Contractor?",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        showCancelButton: true,
        customClass: {
          title: "swal-text",
          popup: "swal-text"
        },
        cancelButtonColor: "#d33",
        confirmButtonText: contractor.status == "INACTIVE" ? "Yes, Active" : "Yes, Inactive",
      }).then((result) => {
        if (result.isConfirmed) {
          statusContractor(contractorId)
            .then(() => {
              search(query);
              Swal.fire({
                toast: true,
                timer: 3000,
                position: "top-right",
                title: contractor.status == "INACTIVE" ?  "Inactive Contractor Successfully!" : "Active Contractor Successfully!",
                showConfirmButton: false,
                icon: "success"
              })
            })
            .catch((e) => { });
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getContractors({
      search: query,
      page: newPage + 1,
      per_page: meta?.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getContractors({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  return (
    <ContractorBlocks
      title="Contractors"
      loading={loading}
      actions={
        <Box display="flex" justifyContent="space-between" alignItems="center" className="memberSearchBox">

          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />

          {canCreateContractor() &&
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: 'auto' }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/app/contractors/create")}
            >
              Add contractor
            </Button>
          }
          <Tooltip title="Import contractor">
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: 'auto', width: "28%" }}
              variant="contained"
              onClick={() => navigate("/app/importContractor")}
            >
              <AddIcon style={{ fontSize: "20px", marginRight: "6px" }} />
              Import Con. Inv 
            </Button>
          </Tooltip>
        </Box>
      }
    >
     <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Contractors"
                value="0"
              />
              {/* <Tab
                label="Open Contractor Invoices"
                value="1"
              /> */}
            </TabList>
          </Box>
          <TabPanel value="0">
            <ContractorData />
          </TabPanel>
          <TabPanel value="1">
           <OpenContractorInvoiceColumnData/>
          </TabPanel>
        </TabContext>
      </Box>
    </ContractorBlocks>
  );
}
