import React, { useEffect, useState } from "react";
import { Tab, Box, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import ServiceLocationsIndex from "./ServiceLocations/Index";
import PhoneNumbers from "./PhoneNumbers";
import useServiceLocations from "hooks/useServiceLocations";
import SearchInput from "components/SearchInput";
import AddIcon from "@mui/icons-material/Add";
import ServiceLocationManagementDialog from "./ServiceLocations/ServiceLocationManagementDialog";

const Settings = () => {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  const [state, _setState] = useState({
    serviceLocations: [],
    serviceLocation: null,
    loading: false,
    activeTab: 0,
    activeRole: 0,
    open: false,
  });
  const {
    serviceLocationsReducer: { results, query, loading, meta },
    getServiceLocations,
    search,
    changePage,
    changePerPage,
  } = useServiceLocations();

  const setState = (newState) => _setState({ ...state, ...newState });

  useEffect(() => {
    setPage({
      title: "Settings",
      path: "/app/settings",
    });
    getServiceLocations(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchValue) => {
    search(searchValue);
  };

  return (
    <>
      <PaperBlock
        title="Settings"
        actions={

          <Box justifyContent="flex-end" className="memberSearchBox custmFlex gap-2">
            <SearchInput
              defaultValue={query}
              onChange={handleSearch}
              placeholder="By Name, City"
            />
            <Button
              sx={{ whiteSpace: "nowrap", minWidth: 'auto' }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setState({ open: true })}
            >
              Add service Location
            </Button>
            <ServiceLocationManagementDialog
              serviceLocation={state.serviceLocation}
              open={state.open}
              onClose={() => setState({ open: false, serviceLocation: null })}
            />
          </Box>
        }
      >
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
                <Tab
                  label="Service Location"
                  value="0"
                />
              </TabList>
            </Box>
            <TabPanel value="0">
              <ServiceLocationsIndex />
            </TabPanel>
            <TabPanel value="1">
              <PhoneNumbers />
            </TabPanel>
          </TabContext>
        </Box>
      </PaperBlock>
    </>
  );
};

export default Settings;
