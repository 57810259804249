import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "../../../../../src/components/Callincoming/index.css";

const OpenContractorInvoiceLink = ({ params }) => {
  const navigate = useNavigate();
  const url = `/app/contractors/${params.id}`;
  const handleLinkClick = (event) => {
    event.preventDefault();
    navigate(url, { replace: true });
  };
  return (
    <a href="#" onClick={handleLinkClick} className='link'>
      {params.value}
    </a>
  );
};

const columns = (renderAction) => [
  {
    field: "con",
    headerName: "Contractor code",
    sortable: true,
    type: "string",
    width: 200,
    renderCell: (params) => <OpenContractorInvoiceLink params={params} />,
  },
  {
    field: 'mem_name',
    headerName: 'Contractor name',
    width: 300,
    editable: true,
    renderCell: (params) => <OpenContractorInvoiceLink params={params} />,
  },
  {
    field: 'mem_street',
    headerName: 'Member Address',
    width: 300,
    editable: true,
  },
  {
    field: 'created_at',
    headerName: 'Date',
    width: 300,
    editable: true,
    renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
  },
  {
    field: 'status',
    headerName: 'Status',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 250,
  },
  {
    field: "action",
    headerName: "Action",
    type: "string",
    // flex: 1,
    sortable: false,
    width: 190,
    renderCell: renderAction,
  },
];

export default columns;