import { Box, Button, Chip, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import useBatch from "hooks/useBatch";
import useQueue from "hooks/useQueue";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queueColors, taskTypeLabels } from "utils/queue";
import AssignedTo from "components/AssignedTo";
import TaskPreview from "components/TaskPreview";
import Toast from "components/Toast";
import { DataGrid } from "@mui/x-data-grid";
import CreateJobModal from "components/CreateJobModal";
import useJobs from "hooks/useJobs";
import useStyles from "components/TaskPreview/styles-jss";

const TaskQueueData = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [openTaskPreview, setOpenTaskPreview] = useState(false);
    const [openCreateJob, setOpenCreateJob] = useState(false);
    const [viewJob, setViewJob] = useState(false);
    const {
        queue: { results, search, meta, query },
        getTasks,
        changeTask,
        changePage,
        changePerPage,
        changeTaskUser
    } = useQueue();
    const {
        jobsReducer,
        getJobsData,
        searchJobs,
      } = useJobs();

    useEffect(() => {
        getTasks(query, search);
    }, []);

    const fetchJons = () => {
        getJobsData(jobsReducer.query);
      }

    const handleViewDetails = (rowData) => {
        if (rowData?.row?.task_type === "NEW_MEMBERSHIP_APPLICATION") {
            return navigate(`/app/members/${rowData?.row?.taskable?.membership?.id}`);
        }
        setOpenTaskPreview(rowData);
    };

    const handleCloseTaskPreview = () => {
        setOpenTaskPreview(false);
    };

    const handleCloseCreateJob = () => {
        setOpenCreateJob(false);
    };

    const handleSwitchToCreateJob = () => {
        setOpenTaskPreview(false); 
        setOpenCreateJob(true);
    };

    const taskResults = results?.map((invoice) => ({
        id: invoice?.id,
        taskable_id: invoice?.taskable_id,
        title: invoice?.title,
        member: invoice?.membership?.members?.[0]?.first_name + " " + invoice?.membership?.members?.[0]?.last_name || '',
        membership_id: invoice?.membership_id,
        taskable: invoice?.taskable,
        status: invoice?.status,
        task_type: invoice?.task_type,
        created_at: invoice?.created_at,
        assigned_to: invoice?.assigned_to,
        code_id: invoice?.taskable?.code_id,
        description: invoice?.taskable?.code?.description,
        contact: `${invoice?.taskable?.contact?.first_name} ${invoice?.taskable?.contact?.last_name} (No. ${invoice?.taskable?.contact?.id})`,
        email: invoice?.taskable?.contact?.email,
        cell_phone: invoice?.taskable?.contact?.cell_phone,
        property: `${invoice?.taskable?.property?.address} ${invoice?.taskable?.property?.city?.label}`,
        state: `${invoice?.taskable?.property?.state} ${invoice?.taskable?.property?.zip}`,
        description: invoice?.taskable?.description,
    }));
    

    const columns = [
        {
          field: 'member',
          headerName: 'Member',
          type: 'string',
          sortable: false,
          flex: 1, 
        },
        {
          field: 'task_type',
          headerName: 'Task Type',
          type: 'string',
          sortable: false,
          flex: 1, 
          renderCell: (params) => <>{taskTypeLabels[params?.value]}</>,
        },
        {
          field: 'created_at',
          headerName: 'Date|Time',
          type: 'string',
          sortable: false,
          flex: 1,
          renderCell: (params) => dayjs(params.value).format('YYYY-MM-DD hh:mm a'),
        },
        {
          field: 'assigned_to',
          headerName: 'Assign to',
          type: 'string',
          sortable: false,
          flex: 2, 
          renderCell: (params) => (
            <AssignedTo
              value={params.value ?? 0}
              onChange={(e) => {
                changeTaskUser(params.id, e.target.value);
                getTasks(query);
                Toast.fire({
                  title: 'Assigned To Updated Successfully!',
                  icon: 'success',
                  position: 'top-right',
                  timer: 3000,
                });
              }}
            />
          ),
        },
        {
          field: 'action',
          headerName: 'Action',
          type: 'string',
          sortable: false,
          flex: 0.5, 
          renderCell: (params) => (
            <Button
              size="small"
              color="primary"
              style={{ backgroundColor: "#116089", color: "#fff" }}
              variant="contained"
              onClick={() => handleViewDetails(params)}
            >
              VIEW
            </Button>
          ),
        },
    ];

    return (
        <>
            {loading ? (
                <CircularProgress style={{ margin: "20px auto", display: "block" }} />
            ) : (
                <Box sx={{ overflow: "auto" }}>
                    <DataGrid
                        columns={columns}
                        rows={taskResults}
                        pageSize={meta?.per_page} 
                        onPageChange={(newPage) => changePage(newPage)}
                        onPageSizeChange={(pageSize) => changePerPage(pageSize)}
                        paginationMode="server"
                        rowCount={meta?.total} 
                        page={meta?.current_page - 1}
                        disableSelectionOnClick
                        autoHeight={false}
                        className={classes.dataGrid}
                        autoHeight
                        getRowId={(row) => row.id}
                    />
                </Box>
            )}
            {openTaskPreview && (
                <TaskPreview
                    getTasks={getTasks}
                    data={openTaskPreview} 
                    handleClose={handleCloseTaskPreview}
                    handleSwitchToCreateJob={handleSwitchToCreateJob}
                />
            )}
            {openCreateJob && (
                <CreateJobModal
                  open={openCreateJob}
                  handleClose={handleCloseCreateJob}
                  fetchJons={fetchJons}
                />
            )}
        </>
    );
};

export default TaskQueueData;
