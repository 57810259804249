import { useNavigate } from 'react-router-dom';
import "../../../../../../src/components/Callincoming/index.css"

const ContractorLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/contractors/${params.id}`;
    const handleLinkClick = (event) => {
        event.preventDefault();
        navigate(url, { replace: true });
    };

    return (
        <a href="#" onClick={handleLinkClick} className='link'>
            {params.value}
        </a>
    );
};

const columns = (renderAction) => [
    {
        field: "alpha_ref",
        headerName: "Contractor code",
        sortable: true,
        type: "string",
        width: 300,
        renderCell: (params) => <ContractorLink params={params} />,
    },
    {
        field: 'name',
        headerName: 'Contractor name',
        width: 300,
        editable: true,
        renderCell: (params) => <ContractorLink params={params} />,
    },
    {
        field: 'phone',
        headerName: 'Phone',
        width: 300,
        editable: true,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 300,
        editable: true,
        // renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
    },
    {
        field: "action",
        headerName: "Action",
        type: "string",
        flex: 0.7,
        sortable: false,
        renderCell: renderAction,
    },
];

export default columns;