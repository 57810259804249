import React from "react";
import InputMask from "react-input-mask";
import FormField from "components/FormField";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "@mui/material";

const EmailField = ({ value, onChange, ...others }) => {
  const handleClick = () => {
    const mailtoUrl = `mailto:${value}`;
    window.open(mailtoUrl, "_blank");
  };

  return (
    <InputMask value={value} onChange={onChange}>
      {() => (
        <FormField
          {...others}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleClick}
                  >
                    Email
                  </Button>
                </React.Fragment>
              </InputAdornment>
            ),
          }}
        />
      )}
    </InputMask>
  );
};

export default EmailField;
