import React, { useEffect, useState } from "react";

import {
    TableCell,
    Button,
    Box,
    Chip,
    CircularProgress,
    Typography
} from "@mui/material";
import Toast from "components/Toast";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { statuses } from "utils/jobStatuses";
import useJobs from "hooks/useJobs";
import useQueue from "hooks/useQueue";
import AssignedToJob from "components/AssignedToJob";
import "../../../../../../src/components/Callincoming/index.css"

const JobsData = () => {
    const navigate = useNavigate()
    const { changeTaskUser } = useQueue();
    // const { setPage } = useApplication();
    const { deleteJob } = useJobs();
    // const [open, setOpen] = useState(false);
    const {
        jobsReducer: { loading, query, results, meta },
        changePage,
        changePerPage,
        getJobsData,
    } = useJobs();

    useEffect(() => {
        getJobsData(query);
    }, [query]);

    const handleChangePage = (newPage) => {
        changePage(newPage);
        getJobsData(query, newPage + 1, meta?.per_page);
    };

    const handleChangeRowsPerPage = (event) => {
        changePerPage(event);
        getJobsData(query, meta?.current_page, parseInt(event.target.value));
    };

    const jobsResults = results?.map((jobData) => ({
        id: jobData?.id,
        name: jobData?.contractor?.name ?? "Unassigned",
        job_type: jobData?.category?.name,
        first_name: `${jobData.contact?.first_name || ''} ${jobData?.contact?.last_name || ''}`,
        property: jobData?.property?.address,
        status: jobData?.status,
        priority: jobData?.priority,
        assigned_to: jobData?.assigned_to,
        membership_id: jobData?.membership_id,
        contractor_id: jobData?.contractor_id
    }));

    const renderAction = (data) => (
        <div style={{ display: "flex", gap: "4px", width: "100%" }}>
            <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ mr: 2.0 }}
                onClick={() => navigate(`/app/jobs/${data.id}`)}
            >
                View
            </Button>
        </div>
    );

    const JobsLink = ({ params }) => {
        const navigate = useNavigate();
        const url = `/app/jobs/${params.id}`;
        const handleLinkClick = (event) => {
            event.preventDefault();
            navigate(url, { replace: true });
        };
        return (
            <a href="#" onClick={handleLinkClick}  className='link'>
                {params.value}
            </a>
        );
    };

    const ContractorLink = ({ data }) => {
        const navigate = useNavigate();
        const url = `/app/contractors/${data.row.contractor_id}`;
        const handleLinkClick = (event) => {
            event.preventDefault();
            navigate(url, { replace: true });
        };
        return (
            <a href="#" onClick={handleLinkClick} className='link'>
                {data.value}
            </a>
        );
    };

    const MembershipLink = ({ params }) => {
        const navigate = useNavigate();
        const url = `/app/members/${params.row.membership}`;
        const handleLinkClick = (event) => {
            event.preventDefault();
            navigate(url, { replace: true });
        };
        return (
            <a href="#" onClick={handleLinkClick} className='link'>
                {params.value}
            </a>
        );
    };

    const columns = (renderAction) => [
        {
            field: 'id',
            headerName: 'ID',
            width: 150,
            renderCell: (params) => <JobsLink params={params} />,
        },
        {
            field: 'name',
            headerName: 'Contractor Name',
            width: 250,
            renderCell: (params) => (
                params?.row?.name === "Unassigned" ? (
                    <Typography>{params?.row?.name}</Typography>
                ) : (
                    <Typography
                        sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                        onClick={() => window.open(`/app/contractors/${params?.row?.contractor_id}`, '_blank')}
                    >
                        {params?.row?.name}
                    </Typography>
                )
            ),
        },
        {
            field: 'job_type',
            headerName: 'Job Type',
            width: 250,
        },
        {
            field: 'first_name',
            headerName: 'Member Name',
            width: 250,
            renderCell: (params) => (
                <Typography
                    sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                    onClick={() => window.open(`/app/members/${params?.row?.membership_id}`, '_blank')}
                >
                    {`${params?.row?.first_name}`}
                </Typography>
            ),
        },
        {
            field: 'property',
            headerName: 'Address',
            type: 'string',
            width: 150,
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            width: 200,
            editable: true,
            renderCell: (params) => (
                <TableCell component="th" scope="row">
                    <Chip
                        label={params?.value}
                        style={{ background: statuses[params?.value], color: "white" }}
                    />
                </TableCell>
            )
        },
        {
            field: 'assigned_to',
            headerName: 'CSA',
            type: "string",
            sortable: false,
            width: 300,
            renderCell: (params) => (
                <AssignedToJob
                    value={params?.row?.assigned_to ?? 0}
                    onChange={(e) => {
                        params.assigned_to = e.target.value;
                        changeTaskUser(params?.row?.id, e.target.value, module = "job", params?.row?.description);
                        getJobsData(query);
                        Toast.fire({
                            title: 'Assigned To Updated Successfully!',
                            icon: "success",
                            position: "top-right",
                            timer: 3000
                        });
                    }}
                />
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: 100,
            renderCell: renderAction,
        },
    ];

    return (
        <>
            {loading ? ( // Display loader when data is being fetched
                <CircularProgress style={{ margin: "20px auto", display: "block" }} />
            ) : (
                <Box sx={{ overflow: "auto" }}>
                    <DataGrid
                        columns={columns(renderAction)}
                        rows={jobsResults}
                        autoHeight
                        pageSize={meta?.per_page} // Use meta.per_page as the page size
                        rowsPerPageOptions={[meta?.per_page]} // Set rows per page options
                        onPageChange={handleChangePage} // Handle page change
                        onPageSizeChange={handleChangeRowsPerPage}
                        rowCount={meta?.total} // Set the total number of rows
                        page={meta?.current_page - 1} // Set the current page
                        disableSelectionOnClick
                    />
                </Box>
            )}
        </>
    )
}
export default JobsData;